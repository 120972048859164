if (window.__ENVIRONMENT__ === "prod") {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-TN2NDQ5");
} else if (window.__ENVIRONMENT__ === "test") {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src =
      "https://www.googletagmanager.com/gtm.js?id=" +
      i +
      dl +
      "&gtm_auth=i-CLgcHrLMSasu3SO3PmXg&gtm_preview=env-4&gtm_cookies_win=x";
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-TN2NDQ5");
}
